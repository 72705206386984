import AboutPage from "./help/_about";

export default AboutPage

export const frontmatter = {
  pageMetaData: {
    refKey: "about",
    title: "SimpleBackups Team",
    description: "A solution built by passionate developers and agency owners. for developers, agencies, startups or any web professionals to manage their backups. We succeed when none of the websites or databases you manage is left without a backup policy in place.",
    author: "simplebackups.com"
  }
}