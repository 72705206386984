import React from "react";
import {Layout, Hero} from "components/layout";
import SEO from "components/seo";
import { Section, Typography, Container, Row } from "components/ui";
import Image from "components/image";

const AboutPage = () => {
    return (
      <Layout>
        <SEO refKey="about" />
        <Section color="alt">
          <Container>
            <Row>
              <div className="col-md-12 text-center">
                <h1 className="font-heading tracking-tight text-5xl md:text-6xl md:leading-tight font-black">About SimpleBackups</h1>
                <Typography tag="h3">A solution built by passionate developers and agency owners.</Typography>
              </div>
            </Row>
          </Container>
        </Section>

        <Section padding>
          <Container>
            <Row>
              <div className="lg:grid grid-cols-12 gap-20">
                <div className="col-span-4">
                  <Typography tag="h3">
                    What drives us.
                  </Typography>
                </div>
                <div className="col-span-8">
                  <Typography tag="p">
                    We both are developers and have been managing agencies, startups and plenty of side projects.
                    We saw the need for a beautiful web application that does the job while keeping things simple.<br/><br/>
                    The main reason why people postpone setting up backups, is that it's a boring task which requires maintenance.<br/><br/>
                    <a href="#">That's why</a> we have decided to build an enjoyable-to-use and no-code solution, with a neat interface making sure
                    nothing blocks you from setting up your backups.
                  </Typography>
                  <Typography tag="p">
                    Our mission is now to provide the necessary solution(s) for developers, agencies, startups
                    or any web professional to manage their backups.<br/><br/>
                    We succeed when none of the websites or databases you manage is left without a backup policy in place.
                  </Typography>
                </div>
              </div>
            </Row>
            <Row>
              <div className="lg:grid grid-cols-12 gap-20">
                <div className="col-span-4">
                  <Typography tag="h3">
                    Meet the founders.
                  </Typography>
                </div>
                <div className="col-span-8">
                  <div className="grid grid-cols-2">
                    <div className="flex items-center justify-center flex-col">
                      <Image className="rounded-full text-center flex align-center justify-center" filename="islamessam" style={{maxWidth: '300px', minWidth: 200}} alt="SimpleBackups founder"></Image>
                      <div className="font-bold text-center mt-3">Islam Essam</div>
                    </div>
                    <a href="https://twitter.com/Lem01" target="_blank" className="flex items-center justify-center flex-col">
                      <Image className="rounded-full text-center flex align-center justify-center" filename="laurent" style={{maxWidth: '300px', minWidth: 200}} alt="SimpleBackups founder"></Image>
                      <div className="font-bold text-center mt-3">Laurent Lemaire</div>
                    </a>
                  </div>
                </div>
              </div>
            </Row>
          </Container>
       </Section>

      </Layout>
    )
}

export default AboutPage
